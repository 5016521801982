<template>
	<div>
		<div class="row">
			<div class="col d-flex justify-content-center text-center">
				<div class="h2 mt-3">
					{{ translate('registration_form_agreement') }}
				</div>
			</div>
		</div>
		<div class="row my-5 pb-1">
			<div class="col-12 col-md-8">
				<flow-info-group>
					<package-overview
						:collapsed="collapsed[0]"
						:loading="systemLoading"
						:cart-items="cartProducts"
						:hide-edit="earlyLaunch"
						:qty-import="qty"
						can-upgrade-package
						@clicked="updateCollapse(0, $event)" />
					<flow-info-section
						:key="`1-${currentStep}`"
						:collapse="collapsed[1]"
						:hide-edit="!canOpenEditing(1)"
						:init-edit="isEditing(1)"
						:title="`${translate('personal_information')}`"
						:hide-controls="isEditing(1)"
						active-color="dark"
						hide-cancel
						override-edit
						show-collapse-indicators
						:no-inner-spacing="isEditing(1)"
						@toggled="goToStep(1);"
						@clicked="updateCollapse(1, $event)">
						<personal-information-overview v-if="!isEditing(1) && !isCollapsed(1)" />
						<personal-information-edit
							v-if="isEditing(1)"
							@cartValidationError="redirectToBeginning"
							@invalidRequest="handleInvalidRequest"
							@saved="getCartTotals" />
					</flow-info-section>
					<flow-info-section
						:key="`2-${currentStep}`"
						:collapse="collapsed[2]"
						:hide-edit="!canOpenEditing(2)"
						:init-edit="isEditing(2)"
						:hide-controls="isEditing(2)"
						:title="`${translate('shipping_information')}`"
						active-color="dark"
						hide-cancel
						override-edit
						show-collapse-indicators
						:no-inner-spacing="isEditing(2)"
						@toggled="goToStep(2);"
						@clicked="updateCollapse(2, $event)">
						<shipping-overview
							v-if="!isEditing(2) && !isCollapsed(2)"
							:shipping-method="shippingMethod" />
						<shipping-edit
							v-if="isEditing(2)"
							@cartValidationError="redirectToBeginning"
							@invalidRequest="handleInvalidRequest"
							@saved="getCartTotals" />
					</flow-info-section>
					<flow-info-section
						:key="`3-${currentStep}`"
						:collapse="collapsed[3]"
						:hide-edit="!canOpenEditing(3)"
						:init-edit="isEditing(3)"
						:title="`${translate('payment_method')}`"
						:hide-controls="isEditing(3)"
						active-color="dark"
						hide-cancel
						override-edit
						show-collapse-indicators
						:no-inner-spacing="isEditing(3)"
						@toggled="goToStep(3);"
						@clicked="updateCollapse(3, $event)">
						<payment-overview
							v-if="!isEditing(3) && !isCollapsed(3)"
							:cart-total="cartNumericTotal"
							:can-split-with-wallet="canSplitWithWallet"
							:excluded-payment-methods="excludedPaymentMethods"
							@goToEdit="goToStep(3)"
							@walletSplit="enableWalletSplitAndEdit" />
						<payment-edit
							v-if="isEditing(3)"
							:payment-method-conditions="paymentMethodConditions"
							:can-split-with-wallet="canSplitWithWallet"
							:wallet-split.sync="walletSplit"
							:cart-total="cartNumericTotal"
							:can-split-payment="canSplitPayment"
							:excluded-payment-methods="excludedPaymentMethods"
							@cartValidationError="redirectToBeginning"
							@invalidRequest="handleInvalidRequest"
							@saved="getCartTotals" />
					</flow-info-section>
					<flow-info-section
						v-if="cartHasTickets"
						:collapse="collapsed[4]"
						init-edit
						hide-controls
						:title="`${translate('event_attendees')}`"
						active-color="dark"
						hide-cancel
						override-edit
						show-collapse-indicators
						:no-inner-spacing="true"
						@clicked="updateCollapse(4, $event)">
						<div
							v-if="!systemLoading">
							<div
								class="col-12 mt-3">
								<p class="my-0 font-weight-bolder text-danger">
									{{ translate('ticket_not_refundable_disclaimer') }}
								</p>
							</div>
							<event-ticket
								v-for="(item, key) in tickets"
								:key="key"
								:ticket-code-name="item.code_name"
								:names="eventAttendees[item.sku]"
								:quantity="item.qty"
								@dataChanged="saveTickets(item.sku, $event)" />
						</div>
						<div
							v-else
							class="col-12 fade-in text-center d-flex justify-content-center align-items-center mt-3 mb-3">
							<div class="col-12">
								<div class="h2">
									<i class="fa fa-fw fa-spinner fa-pulse" />
									<h4 class="mt-3">
										{{ translate('loading') }}
									</h4>
								</div>
							</div>
						</div>
					</flow-info-section>
				</flow-info-group>
			</div>
			<div
				:class="{ 'mt-4': ['xs', 'sm'].includes(windowWidth) }"
				class="col-12 col-md-4">
				<order-summary
					:cart-loading="systemLoading"
					:cart-totals="cartTotals"
					:cart-small-text-totals="cartSmallTextTotals"
					:cart-items="cartProducts"
					:cart-total="cartTotal"
					:has-tickets="cartHasTickets"
					:tickets-info="eventAttendees"
					:total-volume="totalVolume"
					:exchange="exchange.is_needed ? exchange.total : ''"
					:show-disclaimer="isEditing(4)"
					:show-sponsor="!isEditing(1) && !isCollapsed(1)"
					:style="['xs', 'sm'].includes(windowWidth) ? '' : `top: 85px !important;`"
					:class="['xs', 'sm'].includes(windowWidth) ? '' : 'sticky-top'"
					:discount-detail="discountDetail"
					:package-code-name="getProductInfo().package_code_name"
					@cartValidationError="redirectToBeginning"
					@redirectTo="handleRedirect"
					@invalidRequest="handleInvalidRequest" />
			</div>
		</div>
	</div>
</template>
<script>
import DocumentTitle from '@/mixins/DocumentTitle';
import EventTicket from '@/components/EventTicket';
import FlowInfoGroup from '@/components/FlowInfo/Group';
import FlowInfoSection from '@/components/FlowInfo/Section';
import CartData from '@/mixins/CartData';
import PaymentMethods from '@/mixins/PaymentMethods';
import WindowSizes from '@/mixins/WindowSizes';
import { CLEAR_ON_EXIT_PAYMENT_METHODS } from '@/settings/Purchase';
import {
	Cart, Grids, Profile, Purchase, Validations, Events,
} from '@/translations';

import OrderSummary from './components/Confirmation/OrderSummary';
import PackageOverview from './components/Package/Overview';
import PaymentOverview from './components/Payment/Overview';
import PaymentEdit from './components/Payment/Edit';
import PersonalInformationOverview from './components/PersonalInformation/Overview';
import PersonalInformationEdit from './components/PersonalInformation/Edit';
import ShippingEdit from './components/Shipping/Edit';
import ShippingOverview from './components/Shipping/Overview';
import Steps from './mixins/Steps';
import store from './store';
import EarlyLaunch from '@/mixins/EarlyLaunch';
import { REGISTER_STEPS as flowSteps } from '@/settings/Wizard';
import { affiliate, distributor } from '@/settings/Roles';

export default {
	name: 'RegisterConfirmation',
	messages: [Cart, Grids, Profile, Purchase, Validations, Events],
	components: {
		FlowInfoGroup,
		FlowInfoSection,
		OrderSummary,
		PackageOverview,
		PaymentOverview,
		PaymentEdit,
		PersonalInformationOverview,
		PersonalInformationEdit,
		ShippingOverview,
		ShippingEdit,
		EventTicket,
	},
	mixins: [CartData, PaymentMethods, DocumentTitle, Steps, WindowSizes, EarlyLaunch],
	data() {
		return {
			canLeave: false,
			collapsed: {},
			walletSplit: false,
		};
	},
	computed: {
		excludedPaymentMethods() {
			return [];
		},
		canSplitWithWallet() {
			return this.$user.auth() && [distributor, affiliate].includes(this.$user.details().type);
		},
		stepCartId() {
			return store.getters.getStepInformation('cartId');
		},
		paymentMethodSelected() {
			const { payment } = { ...store.getters.getStepInformation('RegisterPayment') };

			// This linting disabler must be removed when the linter is upgraded
			// See: https://github.com/babel/eslint-plugin-babel/pull/163
			// eslint-disable-next-line camelcase
			return payment?.payment_method?.name;
		},
		steps() {
			const steps = [];
			Object.keys(flowSteps).forEach((stepName) => {
				if (flowSteps[stepName].innerSteps) {
					steps.push(...flowSteps[stepName].innerSteps);
				}
				steps.push({ [stepName]: flowSteps[stepName] });
			});
			return steps;
		},
	},
	watch: {
		registerCountry(newVal, oldVal) {
			if (newVal !== oldVal && oldVal !== '') {
				store.dispatch('saveCountry', this.registerCountry);
				this.resetStepsAndRedirect();
			}
		},
		currentStep(newVal) {
			for (let i = this.steps.length - 1; i >= 0; i -= 1) {
				if (this.stepIsCompleted(i) && ((this.cartHasTickets && i === this.steps.length - 1) || (!this.cartHasTickets && i === this.steps.length - 2))) {
					this.collapsed[i] = false;
				} else {
					this.collapsed[i] = i !== newVal;
				}
			}
			if (newVal === 0) {
				this.redirectToBeginning();
			}
		},
		stepCartId(newVal) {
			this.storedCartId = newVal;
		},
		paymentMethodConditions: {
			deep: true,
			handler() {
				if (typeof this.paymentMethodConditions[this.paymentMethodSelected] !== 'undefined' && !this.paymentMethodConditions[this.paymentMethodSelected]?.isAvailable) {
					this.clearPaymentMethodData({ clearSelectedPaymentMethod: true });
				}
			},
		},
		cartNumericTotal(total) {
			this.setAmountToPay(total);
		},
	},
	mounted() {
		this.setFlowName('register');
		if (this.currentStep === 0) {
			this.redirectToBeginning();
		}
		for (let i = 0; i < this.steps.length; i += 1) {
			this.collapsed[i] = true;
		}
		this.getStartingInfo();
	},
	methods: {
		getProductInfo() {
			const info = store.getters.getStepInformation('RegisterPack');
			if (typeof info === 'undefined' || typeof info.product === 'undefined') {
				return null;
			}
			return info.product;
		},
		updateCollapse(step, collapsed) {
			if (this.currentStep === step) {
				this.collapsed[step] = false;
			} else if (this.stepIsCompleted(step)) {
				this.collapsed[step] = !collapsed;
			} else if (!this.stepIsCompleted(step)) {
				this.collapsed[step] = collapsed;
			}
			this.collapsed = { ...this.collapsed };
		},
		async getStartingInfo() {
			const options = {};
			if (typeof this.paymentMethodConditions[this.paymentMethodSelected] !== 'undefined' && !this.paymentMethodConditions[this.paymentMethodSelected]?.isAvailable) {
				options.clearSelectedPaymentMethod = true;
			}
			await this.clearPaymentMethodData(options);

			const firstIncompleteStep = store.getters.getFirstIncompleteStep();
			this.goToStep(firstIncompleteStep);
			this.collapsed[firstIncompleteStep] = false;
			if (this.getProductInfo() === null) {
				this.goToStep(0);
				return null;
			}
			this.initCartData();
			await this.resetIfCountryHasChanged();
			await this.resetIfUserHasChanged();
			return null;
		},
		initCartData() {
			this.useCartCookie = false;
			const {
				is_pack: isPack,
				package_code_name: packageCodeName,
				package_option: packageOption,
				is_package_upgrade: packageUpgrade,
				products,
			} = this.getProductInfo();
			this.cartOptions = {
				is_register: 1,
				is_pack: isPack,
				package_option: packageOption,
				is_package_upgrade: packageUpgrade ? 1 : 0,
				has_additional_products: isPack && Object.keys(products).length,
				package_code_name: packageCodeName,
				sponsor: this.$replicated.replicatedSponsor(),
			};
			this.storedCartId = this.stepCartId;

			this.$watch('cartProducts', (newVal) => {
				if (!newVal.length) {
					this.alert.toast('error', this.translate('empty_cart'), { timer: 6000 });
					setTimeout(() => {
						this.redirectToBeginning();
					}, 6000);
				}
			});
		},
		completedSteps() {
			return store.getters.getCompletedSteps();
		},
		stepIsCompleted(step) {
			return this.completedSteps().includes(step);
		},
		isEditing(step) {
			return this.currentStep === step;
		},
		isCollapsed(step) {
			return this.collapsed[step] ?? true;
		},
		canOpenEditing(step) {
			return this.stepIsCompleted(step);
		},
		handleInvalidRequest() {
			this.createNewCart();
			this.alert.toast('error', this.translate('something_went_wrong'), { timer: 6000 });
			setTimeout(() => {
				this.resetStepsAndRedirect();
			}, 6000);
		},
		resetStepsAndRedirect() {
			return store.dispatch('removeInfo').then(() => Promise.all(
				this.$store.dispatch('position/removeRegister'),
				this.redirectToBeginning(),
			)).catch(() => {});
		},
		redirectToStore() {
			this.canLeave = true;
			this.$router.replace({ name: 'Store' });
		},
		redirectToBeginning() {
			return this.$router.replace({ name: 'RegisterPack' }).catch(() => {});
		},
		handleRedirect(to) {
			this.canLeave = true;
			this.$router.replace({ name: to }).catch(() => {});
		},
		async clearPaymentMethodData(options = {}) {
			const paymentInformation = store.getters.getStepInformation('RegisterPayment');

			// This linting disabler must be removed when the linter is upgraded
			// See: https://github.com/babel/eslint-plugin-babel/pull/163
			// eslint-disable-next-line camelcase
			const paymentMethodName = paymentInformation?.payment?.payment_method?.name;
			const { clearSelectedPaymentMethod } = options;
			if (CLEAR_ON_EXIT_PAYMENT_METHODS.includes(paymentMethodName)) {
				const data = {
					RegisterPayment: {
						payment: {
							billing: paymentInformation.payment.billing,
						},
					},
				};
				if (!clearSelectedPaymentMethod) {
					data.RegisterPayment.payment.payment_method = {
						name: paymentInformation.payment.payment_method.name,
					};
				}
				await store.dispatch('saveInfo', data).then(() => store.dispatch('saveStepAsIncomplete', 3));
				this.goToStep(3);
			}
		},
		async resetIfCountryHasChanged() {
			const storedCountry = store.getters.getRegisterCountry();
			if (storedCountry !== '' && storedCountry !== this.registerCountry) {
				await this.$store.dispatch('position/removeRegister');
				await store.dispatch('removeInfo');
			}
			store.dispatch('saveCountry', this.registerCountry);
		},
		async resetIfUserHasChanged() {
			const storedUser = store.getters.getRegisterUserId();
			if (storedUser !== 0 && storedUser !== this.$user.details().id) {
				await this.$store.dispatch('position/removeRegister');
				await store.dispatch('removeInfo');
			}
			store.dispatch('saveUserId', this.$user.details().id);
		},
		enableWalletSplitAndEdit() {
			this.walletSplit = true;
			this.goToStep(3);
		},
	},
	beforeRouteLeave(to, from, next) {
		if (to.name.includes('Register')) {
			next();
			const alert = new this.$Alert();
			alert.close();
		} else if (this.canLeave) {
			next();
			const alert = new this.$Alert();
			alert.close();
		} else {
			next();
		}
	},
};
</script>
